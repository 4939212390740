// organize-imports-ignore
import { Container } from '@/components/container/Container';
import { ToastContainer } from '@/components/toast/ToastContainer';
import { handleAPIError } from '@/utils/handleAPIError';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import '../styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import Metadata from '@/components/common/Metadata';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    Kakao: any;
  }
}

export default function App({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const isProductDetail = pathname === '/product/[productId]';

  useEffect(() => {
    handleAPIError();
  }, []);

  return (
    <>
      <script
        type="text/javascript"
        src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.NEXT_PUBLIC_MAP_KEY}&autoload=false&libraries=services`}
        async
      />
      <Container>
        {!isProductDetail && <Metadata />}
        <Component {...pageProps} />
        <ToastContainer />
      </Container>
    </>
  );
}
